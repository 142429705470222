.home-section {
  background-image: url("../assets/img/homeBackground.jpg"); /* Add a background image */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: calc(
    100vh - 64px - 20px
  ); /* Ensure the section takes up the full viewport height minus the navbar and footer heights */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeSection {
  padding: 20px;
}

.homeTitle {
  font-weight: bold;
  color: #000 !important; /* Black color for better contrast */
  margin-bottom: 20px;
}

.homeSubtitle {
  color: #000 !important; /* Black color for subtitle */
  margin-bottom: 20px;
  font-size: 2.5em !important; /* Larger font size for the subtitle */
}

.description {
  color: #000 !important; /* Black color for description */
  margin-bottom: 20px;
  font-size: 1.5em !important; /* Larger font size for the subtitle */
}

.funnyQuote {
  color: #272727 !important; /* Grey color for the quote */
  font-style: italic;
  margin-top: 20px;
  font-size: 1.2em !important; /* Larger font size for the subtitle */
}

.profileImageLarge {
  width: 450px !important;
  height: 450px !important;
  margin: 0 auto 20px auto !important; /* Add margin below the image */
}

.socialIcons {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 10px;
  border-radius: 0 5px 5px 0;
}

.socialIcons .MuiIconButton-root {
  color: #fff;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .homeSubtitle {
    font-size: 2em !important;
  }

  .description {
    font-size: 1.2em !important;
  }

  .funnyQuote {
    font-size: 1em !important;
  }

  .profileImageLarge {
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px !important;
  }

  .socialIcons {
    top: 10% !important;
    bottom: auto !important;
    left: 10% !important;
    transform: translateX(-50%);
    flex-direction: column !important;
    border-radius: 5px 5px 0 0 !important;
    margin-right: 10px !important;
  }
}
