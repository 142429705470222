.notFoundContainer {
  text-align: center !important;
  padding: 50px !important;
  margin-top: 50px !important;
}

.notFoundTitle {
  font-size: 6em !important;
  font-weight: bold !important;
  color: #000 !important;
}

.notFoundSubtitle {
  font-size: 2em !important;
  color: #555 !important;
  margin-bottom: 20px !important;
}

.notFoundDescription {
  font-size: 1.2em !important;
  color: #777 !important;
  margin-bottom: 30px !important;
}

.homeButton {
  background-color: #000 !important;
  color: #fff !important;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .notFoundTitle {
    font-size: 3em !important;
  }

  .notFoundSubtitle {
    font-size: 1.5em !important;
  }

  .notFoundDescription {
    font-size: 1em !important;
  }
}
