.iconContainer {
  display: inline-block;
  padding: 5px !important;
}

.icon {
  font-size: 5px !important;
  height: 30px !important;
  width: 30px !important;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .icon {
    font-size: 3px !important;
    height: 20px !important;
    width: 20px !important;
  }
}
