@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.root {
  height: 100% !important; /* Ensure the card takes up the full height of its container */
  width: 100% !important;
  position: relative !important;
  background-color: #ffffffa9 !important; /* Black background */
  color: #000000 !important; /* White text */
  font-family: "Roboto", sans-serif; /* Stylish font */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for distinction */
}

.media {
  height: 300px !important; /* Set a fixed height for the media */
  width: 350px !important; /* Set a fixed width for the media */
  object-fit: contain !important; /* Ensure the logo scales correctly within its container */
  border-radius: 10% !important;
  margin: 0 auto;
}

.cardContent {
  height: 65vh; /* Ensure the card content takes up 75% of the viewport height */
  text-align: left !important; /* Align text to the left */
}

.cardTextContent {
  height: 100% !important; /* Ensure the text content takes up the full height of its container */
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  overflow-y: auto !important; /* Make the content scrollable if needed */
}

.cardActions {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
}

.techStack {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.projectName {
  font-size: 3em !important; /* Make the project name more distinct */
  color: #000000 !important; /* White text */
  font-family: "Pacifico", cursive !important; /* Stylish font */
  text-align: center; /* Center the project name */
  margin-bottom: 20px; /* Add margin to separate it from other content */
}

.projectDescription,
.projectAchievements,
.projectKeyFeatures,
.projectUseCase {
  font-size: 1.2em !important;
  color: #000000 !important; /* Light grey text for description */
}

.projectKeyFeatures ul,
.projectAchievements ul {
  list-style-type: disc;
  margin-left: 40px; /* Indent bullet points */
}

.descriptionTitle {
  font-weight: bold !important;
  font-size: 1.3em !important; /* Make description titles distinct */
  margin-top: 10px;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .media {
    height: 200px !important;
    width: 250px !important;
  }

  .cardContent {
    height: auto;
  }

  .projectName {
    font-size: 2em !important;
  }

  .projectDescription,
  .projectAchievements,
  .projectKeyFeatures,
  .projectUseCase {
    font-size: 1em !important;
  }

  .descriptionTitle {
    font-size: 1.1em !important;
  }
}
