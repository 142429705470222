.projects-section {
  text-align: center;
  background-image: url("../assets/img/projectsBackground1.jpg"); /* Add a background image */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: calc(
    100vh - 64px - 80px
  ); /* Ensure the section takes up the full viewport height minus the navbar and footer heights */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectTitle {
  text-align: center !important;
  justify-content: center !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 3em !important; /* Larger font size for the title */
  color: rgb(0, 0, 0); /* White color for the title */
  font-family: "Roboto", sans-serif; /* Stylish font */
}

.projectCard {
  opacity: 0;
  transform: translateY(20px) scale(0.98) !important;
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ease-out;
  height: 80vh !important; /* Set a fixed height for project cards */
  width: 80% !important; /* Set a fixed width for project cards */
  margin-bottom: 20px !important; /* Add margin between project cards */
  text-align: left !important; /* Align text to the left */
}

.projectCard.in-view {
  opacity: 1;
  transform: translateY(0);
}

.funnyMessage {
  margin-top: 20px;
  font-size: 1.2em;
  color: #555;
  font-style: italic;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .projectTitle {
    font-size: 2em !important;
  }

  .projectCard {
    height: auto !important;
    width: 100% !important;
  }
}
