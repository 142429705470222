.about-section {
  padding: 100px 0; /* Add more spacing between sections */
  text-align: center;
  background-image: url("../assets/img/projectsBackground1.jpg"); /* Add a background image */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: calc(
    100vh - 64px - 80px
  ); /* Ensure the section takes up the full viewport height minus the navbar and footer heights */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutSection {
  padding: 20px;
  text-align: left; /* Align text to the left */
}

.profileImage {
  width: 100%; /* Make the image responsive */
  max-width: 100%; /* Set a maximum width */
  border-radius: 10% !important;
  height: auto;
  margin: 0 auto 20px auto; /* Add margin below the image */
  display: block;
}

.aboutMeTitle {
  font-weight: bold !important;
  margin-bottom: 20px;
  color: #000000 !important; /* Black color for title */
  font-size: 2em !important;
}

.aboutMe {
  margin-top: 16px !important;
  color: #000000 !important; /* Black color for text */
  font-size: 1.2em !important;
}

.hobbiesSection,
.interestsSection {
  padding: 20px;
  margin-top: 40px;
}

.sectionTitle {
  font-weight: bold !important;
  margin-bottom: 20px;
  color: #000000 !important; /* Black color for title */
  font-size: 2.5em !important;
}

.hobbyItem,
.interestItem {
  text-align: center;
}

.interestText {
  color: #000000 !important; /* Black color for text */
  font-size: 1.2em !important;
}

.hobbyIcon,
.interestIcon {
  font-size: 48px !important;
  color: #000 !important; /* Black color for icons */
  margin-bottom: 10px;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .about-section {
    padding: 50px 0;
  }

  .aboutMeTitle {
    font-size: 1.5em !important;
  }

  .aboutMe {
    font-size: 1em !important;
  }

  .sectionTitle {
    font-size: 2em !important;
  }

  .interestText {
    font-size: 1em !important;
  }

  .hobbyIcon,
  .interestIcon {
    font-size: 36px !important;
  }
}
