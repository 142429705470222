.footer {
  width: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  z-index: 1000; /* Ensure this is lower than the chat bubble */
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .footer {
    padding: 10px 0;
  }
}
