.contactContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 500px; /* Make the form wider */
  padding: 20px;
  background: rgba(255, 255, 255, 0.95); /* Slightly transparent background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for distinction */
  border-radius: 10px 10px 0 0; /* Rounded top corners */
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 3000;
}

.contactContainer.open {
  transform: translateY(0);
}

.contactPaper {
  padding: 20px;
  text-align: center;
}

.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.contactTitle {
  font-weight: bold;
  color: #000 !important; /* Black color for title */
}

.successMessage {
  color: green;
  margin-bottom: 20px;
}

.infoMessage {
  color: #555;
  margin-bottom: 20px;
}

.contactForm {
  margin-top: 20px;
}

.submitButton {
  background-color: #000 !important;
  color: #fff !important;
  margin-top: 20px;
  width: 100%;
}

.submitButton:hover {
  background-color: #ffffff !important; /* Darken the background color on hover */
  color: #000 !important; /* Change the text color on hover */
  border: 1.5px solid #000 !important; /* Add a border on hover */
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .contactContainer {
    max-width: 100%;
    padding: 10px;
  }

  .contactPaper {
    padding: 10px;
  }

  .contactHeader {
    margin-bottom: 10px;
  }

  .submitButton {
    margin-top: 10px;
  }
}
