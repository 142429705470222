.resume-section {
  padding: 80px 0; /* Add spacing between sections */
  text-align: center;
  background-image: url("../assets/img/projectsBackground1.jpg"); /* Add a background image */
  background-size: cover; /* Ensure the background image covers the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: calc(
    100vh - 64px - 80px
  ); /* Ensure the section takes up the full viewport height minus the navbar and footer heights */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paper {
  padding: 24px !important;
  text-align: left !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5) !important; /* Adjust shadow as desired */
  margin-bottom: 20px;
}

.button {
  margin: 16px !important;
  display: block !important; /* Center the button */
  margin-left: auto !important;
  margin-right: auto !important;
  background-color: black !important;
  color: white !important;
}

.resumeTitle {
  text-align: center !important;
  justify-content: center !important;
  font-weight: bold !important;
  font-style: italic !important;
  font-size: 3em !important; /* Larger font size for the title */
  margin-bottom: 40px;
  color: #000000 !important; /* Black color for better contrast */
}

.sectionTitle {
  font-weight: bold !important;
  margin-bottom: 10px;
}

.sectionContent {
  margin-bottom: 10px;
}

.thankYouMessage {
  margin-top: 10px;
  font-style: italic;
  color: #ffffff;
  font-size: 2em;
}

.highlight {
  background-color: #000000 !important; /* Black background for highlights */
  padding: 5px;
  border-radius: 5px;
  color: white !important;
}

.technicalHighlight {
  background-color: #d1e7dd !important; /* Light green background for technical highlights */
  padding: 2px 4px;
  border-radius: 3px;
}

.businessHighlight {
  background-color: #ffeeba !important; /* Light yellow background for business highlights */
  padding: 2px 4px;
  border-radius: 3px;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .resumeTitle {
    font-size: 2em !important;
  }

  .sectionTitle {
    font-size: 1.5em !important;
  }

  .sectionContent {
    font-size: 1em !important;
  }

  .thankYouMessage {
    font-size: 1.5em !important;
  }
}
